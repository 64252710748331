<template>
  <div class="bg-default">
    <header class="header hidden">
      <div class="header-left">
        <a href="#">
          <img
            class="header-settings-icon"
            src="img/settings-icon.png"
            alt="Settings"
          />
        </a>
      </div>
      <div class="header-center"></div>
      <div class="header-right">
        <a @click="exit()">
          <img
            class="header-settings-icon"
            src="img/exit-icon.png"
            alt="Exit"
          />
        </a>
      </div>
    </header>
    <main class="main">
      <div class="container">
        <div class="summary">
          <div class="summary-left">
            <div class="summary-left-card">
              <div class="summary-left-card-img">
                <img src="img/startmoney-icon.png" alt="Start money icon" />
              </div>
              <div class="summary-left-card-title">
                Induló<br />
                tallérok
              </div>
              <div class="summary-left-card-number">
                {{ ogBalance }}
              </div>
            </div>
            <div class="summary-left-card">
              <div class="summary-left-card-img">
                <img
                  src="img/currency-bonus-icon.png"
                  alt="Currency bonus icon"
                />
              </div>
              <div class="summary-left-card-title">
                Deviza váltás<br />
                bónusz
              </div>
              <div class="summary-left-card-number">
                {{ devizaBonus ? $config.game.bonuses.exchangeBonus : "0" }}
              </div>
            </div>
            <div class="summary-left-card">
              <div class="summary-left-card-img">
                <img src="img/graph-icon.png" alt="Graph icon" />
              </div>
              <div class="summary-left-card-title">
                Tőzsdei<br />
                kereskedés
              </div>
              <div class="summary-left-card-number">
                {{ profit.toString() }}
              </div>
            </div>
            <div class="summary-left-card">
              <div class="summary-left-card-img">
                <img src="img/best-bonus-icon.png" alt="Best bonus icon" />
              </div>
              <div class="summary-left-card-title">
                Legjobb kereskedő<br />
                bónusz
              </div>
              <div class="summary-left-card-number">1400</div>
            </div>
          </div>
          <div class="summary-right">
            <div class="summary-right-card">
              <div class="summary-right-card-img">
                <div class="summary-right-card-img-treasure-chest">
                  <img src="img/treasure-chest.png" alt="Treasure chest" />
                </div>
              </div>
              <div class="summary-right-card-title">
                Összesített<br />
                egyenleged
              </div>
              <div class="summary-right-card-number">
                <span>{{ balance }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer class="footer"></footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["balance", "devizaBonus", "ogBalance", "profit", "groupId","actualGameTurnId", "stopGameTurnId"]),
  },
  watch: {
      stopGameTurnId(newStop,oldStop) {
          if(newStop !== this.actualGameTurnId) {
      //this.$store.dispatch('storeGameplayEvent', {round: 0,event: "wait"});
       this.$router.push('end');
     }
      }
  }
};
</script>

<style>
</style>